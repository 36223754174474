<!--会员列表-->

<template>
  <div class="main-container">
    <el-form style="background: white;padding: 20px 20px 0" size="mini" :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="一级代理">
        <el-select v-model="formInline.region1" placeholder="请选择一级代理">
          <el-option
            v-for="item in primaryAgencyOption"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="二级代理">
        <el-select v-model="formInline.region2" placeholder="请选择二级代理">
          <el-option
            v-for="item in primaryAgencyOption"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>


      <el-form-item label="级别">
        <el-select v-model="formInline.region3" placeholder="请选择级别">
          <el-option
            v-for="item in primaryAgencyOption"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="连单">
        <el-select v-model="formInline.region4" placeholder="请选择连单">
          <el-option
            v-for="item in primaryAgencyOption"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="状态">
        <el-select v-model="formInline.region5" placeholder="请选择状态">
          <el-option
            v-for="item in primaryAgencyOption"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>




      <!--<el-form-item label="业务员">-->
      <!--  <el-select v-model="formInline.region2" placeholder="请选择业务员">-->
      <!--    <el-option-->
      <!--      v-for="item in salesmanOption"-->
      <!--      :key="item.value"-->
      <!--      :label="item.label"-->
      <!--      :value="item.value">-->
      <!--    </el-option>-->
      <!--  </el-select>-->
      <!--</el-form-item>-->
      <!--<el-form-item label="叠加组">-->
      <!--  <el-select v-model="formInline.region3" placeholder="请选择叠加组">-->
      <!--    <el-option-->
      <!--      v-for="item in groupOption"-->
      <!--      :key="item.value"-->
      <!--      :label="item.label"-->
      <!--      :value="item.value">-->
      <!--    </el-option>-->
      <!--  </el-select>-->
      <!--</el-form-item>-->


      <el-form-item label="排序方式">
        <el-select v-model="formInline.region6" placeholder="请选择排序方式">
          <el-option
            v-for="item in sortModelOption"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="用户名称">
        <el-input v-model="formInline.username" placeholder="请输入用户名称"></el-input>
      </el-form-item>
      <el-form-item label="手机号码">
        <el-input v-model="formInline.account" placeholder="请输入手机号码"></el-input>
      </el-form-item>
      <el-form-item label="邀请码">
        <el-input v-model="formInline.inviteCode" placeholder="请输入邀请码"></el-input>
      </el-form-item>
      <el-form-item label="BTC">
        <el-input v-model="formInline.region10" placeholder="请输入BTC"></el-input>
      </el-form-item>
      <el-form-item label="TRX">
        <el-input v-model="formInline.region11" placeholder="请输入TRX"></el-input>
      </el-form-item>
      <el-form-item label="ETH">
        <el-input v-model="formInline.region12" placeholder="请输入ETH"></el-input>
      </el-form-item>
      <el-form-item label="UID">
        <el-input v-model="formInline.uid" placeholder="请输入UID"></el-input>
      </el-form-item>
      <el-form-item label="IP">
        <el-input v-model="formInline.region14" placeholder="请输入IP"></el-input>
      </el-form-item>
      <el-form-item label="注册时间">
        <el-input v-model="formInline.region15" placeholder="请输入注册时间"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getMemberList">搜索</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary">导出</el-button>
      </el-form-item>
      <el-form-item label="打针幅度" >
        <div style="display: flex">
          <el-input  placeholder="最低1.1">

          </el-input>
          <el-button style="margin-left: 10px" type="primary">批量打针</el-button>
        </div>

      </el-form-item>
    </el-form>

    <TableBody ref="tableBody">
      <template>
        <el-table
          ref="table"
          v-loading="tableLoading"
          :data="dataList"
          :header-cell-style="tableConfig.headerCellStyle"
          :size="tableConfig.size"
          :stripe="tableConfig.stripe"
          :border="false"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="45"
          />
          <el-table-column
            align="center"
            label="UID"
            width="80"
            prop="uid"
          >
          </el-table-column>

          <el-table-column
            align="center"
            label="手机号"
            width="120"
            prop="account"
          >
          </el-table-column>
          <el-table-column
            align="center"
            min-width="160"
            label="注册时间"
            prop="registerTime"
          />

          <el-table-column
            align="center"
            label="最后做单时间"
            prop="grabTime"
            width="130px"
          />


          <el-table-column
            align="center"
            label="一级代理"
            prop="belongAgentLevel1"
          ></el-table-column>

            <el-table-column
              align="center"
              label="二级代理"
              prop="belongAgentLevel2"
            >
            </el-table-column>

          <!--</el-table-column>-->
          <!--<el-table-column-->
          <!--  align="center"-->
          <!--  label="业务员"-->
          <!--  prop=""-->
          <!--/>-->
          <el-table-column
            align="center"
            label="账号"
            prop="account"
            width="160px"
          />
          <el-table-column
            align="center"
            label="备注"
            prop="comment"
            width="160px"
          />
          <!--<el-table-column-->
          <!--  align="center"-->
          <!--  label="国家"-->
          <!--  prop="area"-->
          <!--  width="130px"-->
          <!--/>-->
          <!--<el-table-column-->
          <!--  align="center"-->
          <!--  label="抢单开关"-->
          <!--&gt;-->
          <!--  <template slot-scope="scope">-->
          <!--    <el-switch-->
          <!--      v-model="scope.row.grabSwitch"-->
          <!--      :active-value="1"-->
          <!--      :inactive-value="0"-->
          <!--    />-->
          <!--  </template>-->
          <!--</el-table-column>-->

          <!--<el-table-column-->
          <!--  align="center"-->
          <!--  label="用户名"-->
          <!--  prop="username"-->

          <!--/>-->
              <el-table-column
                align="center"
                label="上级用户"
                prop="upLevelUserName"

              />

          <!--<el-table-column-->
          <!--  align="center"-->
          <!--  label="赠金"-->
          <!--  prop="giveCash"-->
          <!--  width="130px"-->
          <!--/>-->
          <el-table-column
            align="center"
            label="账户余额"
            prop="balance"

          />
          <el-table-column
            align="center"
            label="佣金"
            prop="commission"

          />


          <el-table-column
            align="center"
            label="下级佣金"
            prop="levelCommission"

          />
          <el-table-column
            align="center"
            label="累计充值金额"
            prop="topUpTotalBalance"
            width="130px"
          />
          <el-table-column
            align="center"
            label="累计充值次数"
            prop="topUpTotalCount"
            width="130px"
          />
          <el-table-column
            align="center"
            label="累计提现金额"
            prop="withdrawalTotalBalance"
            width="130px"
          />
          <el-table-column
            align="center"
            label="累计提现次数"
            prop="withdrawalTotalCount"
            width="130px"
          />



          <el-table-column
            align="center"
            label="冻结金额"
            prop="freezeAmount"
            width="130px"
          />
          <!--<el-table-column-->
          <!--  align="center"-->
          <!--  label="上级用户"-->
          <!--  prop="upLevelUserName"-->
          <!--  width="130px"-->
          <!--/>-->
          <el-table-column
            align="center"
            label="邀请码"
            prop="inviteCode"
            width="130px"
          />



          <el-table-column
            align="center"
            label="最后登录时间"
            prop="loginTime"
            width="200px"
          />
          <el-table-column
            align="center"
            label="最后登录ip"
            prop="ip"
            width="130px"
          />
          <!--新加-->
          <el-table-column
            align="center"
            label="注册ip"
            prop="registerIp"
            width="130px"
          />
          <!--新加-->
          <el-table-column
            align="center"
            label="状态"
            prop=""
            width="130px"
          >
            <template slot-scope="scope">
              <span :class="{'on': scope.row.status == '0', 'off': scope.row.status == '-1'}">
                 {{scope.row.status == '0' ? '正常' : '禁用'}}
              </span>

            </template>
          </el-table-column>
          <!--<el-table-column-->
          <!--  align="center"-->
          <!--  label="备注"-->
          <!--  prop="comment"-->
          <!--  width="130px"-->
          <!--/>-->
          <el-table-column
            align="center"
            width="280"
            label="操作"
            fixed="right"

          >
            <template slot-scope="scope">
              <el-button class="button-small btnGreen" @click="edit(scope.row)" size="mini">编辑</el-button>
              <el-button class="button-small btnYellow" size="mini">公告</el-button>
              <el-button class="button-small" size="mini" type="primary">账变</el-button>
              <el-button class="button-small" size="mini" type="danger">打针</el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-dialog
          title="编辑"
          :visible.sync="dialogVisible"
          width="30%"
         >
          <el-form size="mini" style="width: 300px"  ref="form" :model="form" label-width="80px">
            <el-form-item label="账户余额:">
              <el-input oninput ="value=value.replace(/[^\-?\d.]/g,'')" v-model="form.balance"></el-input>
            </el-form-item>
            <el-form-item label="密码:">
              <el-input type="password"   v-model="form.password"></el-input>
            </el-form-item>
            <el-form-item label="提现密码:">
              <el-input type="password"   v-model="form.withdrawalPassword"></el-input>
            </el-form-item>
            <el-form-item label="冻结金额:">
              <el-input oninput ="value=value.replace(/[^\-?\d.]/g,'')" v-model="form.freezeAmount"></el-input>
            </el-form-item>

            <el-form-item label="备注:">
              <el-input v-model="form.comment"></el-input>
            </el-form-item>
            <el-form-item label="禁用开关:">
              <el-switch
                v-model="form.status"
                :active-value="0"
                :inactive-value="-1"
              />
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="confirmEdit(form)">确 定</el-button>
  </span>
        </el-dialog>

      </template>


      <!-- 分页区域 -->
      <div class="serviceManage-page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.size"
          :page-sizes="[10, 20, 30, 40]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>


    </TableBody>
  </div>
</template>

<script>
import {memberList, memberEdit} from '../../../api/index'
import TableMixin, { PageModelMixin } from '@/mixins/TableMixin'
import { GetDataMixin, DeleteItemsMixin } from '@/mixins/ActionMixin'
import Page403 from "@/views/exception/403";
import md5 from 'crypto-js/md5';
export default {
  name: 'memberList',
  components: {Page403},
  mixins: [TableMixin, PageModelMixin, GetDataMixin, DeleteItemsMixin],
  data() {
    return {
      dialogVisible: false,
      form: {
        balance: 0,
        freezeAmount: 0,
        password: '',
        withdrawalPassword: '',
        comment: '',
        status: ''

      },
      formInline: {

        inviteCode: '',
        uid: '',
        account: ''
      },
      pagination: {
        page: 1,
        size: 20
      },
      total: '',
      primaryAgencyOption: [{
        label: '全部',
        value: ''
      }],
      salesmanOption: [{
        label: '全部',
        value: ''
      }],
      groupOption: [{
        label: '全部',
        value: ''
      }],
      sortModelOption: [{
        label: '充值金额倒序',
        value: ''
      },
        {
          label: '充值次序倒序',
          value: ''
        },
        {
          label: '提现金额倒序',
          value: ''
        },
        {
          label: '提现次数倒序',
          value: ''
        }],
    }
  },
  mounted() {
    this.getMemberList()

  },
  methods: {
    setmd5(val) {
      if (val) {
        return md5(val).toString()
      } else {
        return ''
      }

    },
    edit(row) {
      this.form.comment = row.comment
      this.form.account = row.account
      this.form.status = row.status
      this.form.password = ''
      this.form.withdrawalPassword = ''
      this.form.balance = 0
      this.form.freezeAmount = 0

      this.dialogVisible = true
    },

    confirmEdit() {

      const data = {
        account: this.form.account,
        balance: Number(this.form.balance),
        status: this.form.status,
        comment: this.form.comment,
        password: this.setmd5(this.form.password),
        withdrawalPassword: this.setmd5(this.form.withdrawalPassword),
        freezeAmount: this.form.freezeAmount
      }
      if ((this.form.password && this.form.password.length < 6) || (this.form.withdrawalPassword && this.form.withdrawalPassword.length < 6)) {
        this.$errorMsg('密码需要六位及以上')
        return
      }
      memberEdit(data).then(res => {
        if (res.data.status == 0) {
          this.dialogVisible = false
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.getMemberList()
        }
      })
    },

    // 获取会员列表
    getMemberList() {
      memberList({...this.pagination, ...this.formInline}).then(res => {
        this.dataList = res.data.result
        this.total = res.data.total

      })
    },
    handleSizeChange(size) {
      this.pagination.size = size
      this.formInline.page = 1
      this.getMemberList()
    },
    handleCurrentChange(page) {
      this.pagination.page = page
      this.getMemberList()
    }
  }

}
</script>
<style lang="scss" scoped>

.serviceManage-page {
  height: 48.5px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding-left: 24px;
}


.avatar-container {
  position: relative;
  width: 30px;
  margin: 0 auto;
  vertical-align: middle;
  .avatar {
    width: 100%;
    border-radius: 50%;
  }
  .avatar-vip {
    border: 2px solid #cece1e;
  }
  .vip {
    position: absolute;
    top: 0;
    right: -9px;
    width: 15px;
    transform: rotate(60deg);
  }
}
.gender-container {
  .gender-icon {
    width: 20px;
  }
}
.button-small {
  padding: 6px 12px;
}
.btnGreen {
  background: #009688;
  color: white
}
.btnYellow {
  background: rgba(255, 184, 0);
  color: white;
}

.el-table__body-wrapper::-webkit-scrollbar {
  height: 20px;
  width: 20px;
}
::-webkit-scrollbar {
  height: 20px;
  width: 20px;
}

.el-table__body-wrapper::-webkit-scrollbar{
  width: 12px !important;
  height: 12px;

}




.el-table__body-wrapper::-webkit-scrollbar,
.el-table__body-wrapper::-webkit-scrollbar-thumb {
  width: 20px; //垂直方向
  height: 20px!important; //水平方向
}

.el-table__body-wrapper::-webkit-scrollbar {
  width: 20px; //垂直方向
  height: 20px!important; //水平方向
}

</style>
